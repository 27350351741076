<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->

      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          <el-radio-group v-model="form.stations" @change="loadArticles(1)">
            <el-radio-button label="1,2,3">客服</el-radio-button>
            <el-radio-button label="2,3">FAE</el-radio-button>
            <el-radio-button label="3">研发</el-radio-button>
          </el-radio-group>
          <el-cascader
            class="leftgap"
            v-show="form.stations === '3'"
            v-model="selectdept"
            type="cascade"
            :options="treedata"
            :props="{ checkStrictly: true, label: 'name', value: 'deptId' }"
            placeholder="请选择部门"
            clearable
            @change="handleSelectDept"
          />
        </div>
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="loadArticles(1)"
            >查询</el-button
          >

          <el-button type="success" :disabled="loading" @click="exportexcel()"
            >导出</el-button
          >

          <el-button type="info" :disabled="loading" @click="clearAndSearch()"
            >清空</el-button
          >
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
            >{{ ifgaoji ? "收起" : "高级查询" }}</el-button
          >
        </div>
        <div v-if="ifgaoji" class="flexparents zdhh">
          <div>
            工单号:
            <el-input
              class="input rightgap"
              v-model="form.workOrderNo"
              placeholder="请输入工单号"
            ></el-input>
          </div>
          <div>
            创建人:
            <el-input
              class="input rightgap"
              v-model="form.createdName"
              placeholder="请输入创建人"
            ></el-input>
          </div>
          <div>
            是否转出:
            <el-select
              class="flexparents rightgap"
              v-model="form.transformFlag"
              placeholder="请选择"
            >
              <el-option
                v-for="item in transformFlagoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            客诉类型:
            <el-select
              v-model="form.customerComplaintType"
              placeholder="请选择"
              class="rightgap"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            进度查询:
            <el-select
              class="flexparents rightgap"
              v-model="form.processes"
              placeholder="请选择"
            >
              <el-option
                v-for="item in stateoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            客户姓名:
            <el-input
              class="input rightgap"
              v-model="form.customerName"
              placeholder="请输入客户"
            ></el-input>
          </div>
          <div>
            手机号:
            <el-input
              class="input"
              v-model="form.phoneNumber"
              placeholder="请输入客诉手机号"
            ></el-input>
          </div>
          <div>
            诉源:
            <el-select
              class="flexparents rightgap"
              v-model="form.selfFlag"
              placeholder="请选择"
            >
              <el-option
                v-for="item in fromoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div>
            紧急程度:
            <el-select
              class="flexparents rightgap"
              v-model="form.urgentType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in urgentTypeoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <el-switch
            v-model="form.currentUserFlag"
            active-text="全部员工"
            inactive-text="当前员工"
            active-value="false"
            inactive-value="true"
          >
          </el-switch>
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <!-- 数据列表 -->
      <!--
        Table 表格组件
        1、把需要展示的数组列表数据绑定给 table 组件的 data 属性
          注意：你不用去 v-for 遍历，它自己会遍历
        2、设计表格列 el-table-column
          width 可以设定表格列的宽度
          label 可以设定列的标题
          prop  用来设定要渲染的列表项数据字段，只能展示文本

        3、表格列默认只能渲染普通文本，如果需要展示其它内容，例如放个按钮啊、放个图片啊，那就需要自定义表格列模板了：https://element.eleme.cn/#/zh-CN/component/table#zi-ding-yi-lie-mo-ban
       -->
      <el-table
        v-show="form.stations === '1,2,3'"
        :data="articles"
        stripe
        height="1150"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" label="工单号"> </el-table-column>
        <el-table-column prop="createdName" label="创建人"></el-table-column>

        <el-table-column label="溯源">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="Station[scope.row.createdByStation].type">{{
              Station[scope.row.createdByStation].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="customerName" label="客户姓名">
        </el-table-column>
        <el-table-column label="紧急程度" width="120">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="当前工站">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="Station[scope.row.station].type">{{
              Station[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="title" label="客诉标题"> </el-table-column>
        <el-table-column width="250" prop="description" label="问题描述">
        </el-table-column>

        <el-table-column prop="phoneNumber" label="客户手机号">
        </el-table-column>
        <el-table-column width="250" prop="remark" label="客诉详情">
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column label="客诉类型">
          <template slot-scope="scope">
            <el-tag
              effect="plain"
              :type="articleStatus[scope.row.customerComplaintType].type"
              >{{ articleStatus[scope.row.customerComplaintType].text }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="进度">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="progress[scope.row.process].type">{{
              progress[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column width="120" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="openDetails(scope.row)"
                  circle
                  icon="el-icon-tickets"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="跳转FAE"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  :disabled="!scope.row.transformFlag"
                  size="mini"
                  @click="transtofae(scope.row)"
                  circle
                  icon="el-icon-s-promotion"
                  :type="scope.row.transformFlag ? 'primary' : 'info'"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        v-show="form.stations === '2,3'"
        :data="articles"
        stripe
        height="650"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" width="160" label="工单号">
        </el-table-column>
        <el-table-column prop="createdTime" width="140" label="创建时间">
        </el-table-column>
        <el-table-column label="问题状态">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="transformFlag" label="当前工站">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="Station[scope.row.station].type">{{
              Station[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createdName" label="创建人"></el-table-column>
        <el-table-column prop="area" label="大区">
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template></el-table-column
        >
        <el-table-column
          prop="customerName"
          label="客户(国内)"
        ></el-table-column>
        <el-table-column prop="salesman" label="销售"></el-table-column>
        <el-table-column prop="platform" label="平台" key="platform">
          <template slot-scope="scope">
            {{
              pingtaioptions[scope.row.platform] &&
              pingtaioptions[scope.row.platform].label
            }}</template
          >
        </el-table-column>
        <el-table-column
          prop="personLiable"
          label="研发跟进人"
        ></el-table-column>
        <el-table-column prop="productType" label="产品类型">
          <template v-slot="scope">
            {{
              prodectoptions[scope.row.productType] &&
              prodectoptions[scope.row.productType].label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="networkType" label="网络制式"></el-table-column>
        <el-table-column prop="productModelType" label="机型"></el-table-column>
        <el-table-column prop="keyword" label="客诉类型"></el-table-column>
        <el-table-column
          prop="description"
          width="300"
          label="问题描述"
        ></el-table-column>
        <el-table-column label="紧急程度">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <!-- <el-table-column
          width="110"
          prop="stuckPoint"
          label="当前卡点"
        ></el-table-column>
        <el-table-column
          width="600"
          prop="solution"
          label="问题进度"
        ></el-table-column>

        <el-table-column
          prop="transformTime"
          width="140"
          label="转至时间"
        ></el-table-column>
        <el-table-column
          prop="completedTime"
          width="140"
          label="问题完成时间"
        ></el-table-column> -->
        <el-table-column prop="type" label="客诉类型">
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template></el-table-column
        >

        <!-- <el-table-column
          prop="feedbackTimes"
          label="反馈次数"
        ></el-table-column> -->
        <el-table-column
          prop="remark"
          width="250"
          label="备注"
        ></el-table-column>

        <el-table-column width="140" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="openFAEDetails(scope.row)"
                  circle
                  icon="el-icon-tickets"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="查看时间线"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showdtimeline(scope.row)"
                  circle
                  icon="el-icon-time"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="跳转研发"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  :disabled="!scope.row.transformFlag"
                  size="mini"
                  @click="transtoyanfa(scope.row)"
                  circle
                  icon="el-icon-s-promotion"
                  :type="scope.row.transformFlag ? 'primary' : 'info'"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        :data="articles"
        stripe
        height="1150"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-show="form.stations === '3'"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" width="160" label="工单号">
        </el-table-column>
        <el-table-column label="问题状态">
          <template slot-scope="scope">
            <div v-if="scope.row.process">已完成</div>
            <div v-else>未完成</div>
          </template>
        </el-table-column>
        <el-table-column prop="createdName" label="创建人"></el-table-column>
        <el-table-column prop="area" label="大区">
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="客户(国内)"
        ></el-table-column>

        <el-table-column prop="deptName" label="所属部门"></el-table-column>
        <el-table-column prop="salesman" label="销售"></el-table-column>
        <el-table-column prop="type" label="客诉类型">
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template></el-table-column
        >
        <el-table-column prop="productType" label="产品类型">
          <template v-slot="scope">
            {{
              prodectoptions[scope.row.productType] &&
              prodectoptions[scope.row.productType].label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="networkType" label="网络制式"></el-table-column>
        <el-table-column prop="productModelType" label="机型"></el-table-column>
        <el-table-column
          prop="description"
          width="300"
          label="问题描述"
        ></el-table-column>
        <el-table-column label="紧急程度">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
             <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag> 
          </template>
        </el-table-column>

        <el-table-column
          prop="createdTime"
          width="140"
          label="提出时间"
        ></el-table-column>

        <el-table-column
          prop="remark"
          width="250"
          label="备注"
        ></el-table-column>
        <el-table-column width="50" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="addplan(scope.row)"
                  circle
                  icon="el-icon-tickets"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <!-- <el-tooltip
                content="转交部门"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showtranslatewindows(scope.row)"
                  circle
                  icon="el-icon-position"
                  type="primary"
                ></el-button>
              </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- /数据列表 -->

      <!-- 列表分页 -->
      <!--
        total 用来设定总数据的条数
        它默认按照 10 条每页计算总页码
        page-size 每页显示条目个数，支持 .sync 修饰符，默认每页 10 条

        90 3 90 / 3 = 30
       -->
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      title="时间线列表"
      width="550px"
      :visible.sync="timelineViewShow"
      append-to-body
    >
      <el-timeline reverse="false">
        <el-timeline-item
          v-for="(activity, index) in timelinedata"
          :key="index"
          :timestamp="activity.operateTime"
        >
          {{ activity.operateDescription }}
        </el-timeline-item>
      </el-timeline>
    </el-dialog>

    <el-dialog
      width="850px"
      title="查看详情"
      :visible.sync="detaileVisible"
      append-to-body
    >
      <KFDetaileView :dataildata="dataildata" />
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看详情"
      :visible.sync="planVisable"
      append-to-body
      width="1100px"
    >
      <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step title="开始" :description="FAEdatile.createdTime"></el-step>
        <el-step
          title="FAE处理中"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="转发至研发"
          :description="yanfadatile.createdTime"
        ></el-step>
        <el-step
          title="研发处理中"
          :description="yanfadatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="研发处理完成"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          title="FAE确认完成中"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step title="完成" :description="FAEdatile.completedTime"></el-step>
      </el-steps>
      <div class="destitle">基本信息</div>
      <el-descriptions class="desbasebody" :column="3">
        <el-descriptions-item label="订单号">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item label="地区">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item label="客诉类型">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item label="涉及平台">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>
        <el-descriptions-item label="紧急程度">
          {{
            urgentStatus[FAEdatile.urgentType]
              ? urgentStatus[FAEdatile.urgentType].text
              : ""
          }}
        </el-descriptions-item>
        <el-descriptions-item label="FAE处理人">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="研发跟进人">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          FAEdatile.createdName
        }}</el-descriptions-item>
      </el-descriptions>

      <template v-if="esservedatiledata.orderNo">
        <div class="destitle">电商详情</div>
        <div class="desbody">
          <el-descriptions :column="4">
            <el-descriptions-item label="IMEI">{{
              esservedatiledata.imei
            }}</el-descriptions-item>
            <el-descriptions-item label="平台/APP">{{
              esservedatiledata.platform
            }}</el-descriptions-item>
            <el-descriptions-item label="设备状态">{{
              esservedatiledata.deviceStatus
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              esservedatiledata.createdTime
            }}</el-descriptions-item>
            <el-descriptions-item label="购买渠道">{{
              esservedatiledata.channel
            }}</el-descriptions-item>
            <el-descriptions-item label="国家">{{
              esservedatiledata.country
            }}</el-descriptions-item>
            <el-descriptions-item label="其他联系方式">{{
              esservedatiledata.otherContactInfo
            }}</el-descriptions-item>
            <el-descriptions-item label="设备LED灯状态">{{
              esservedatiledata.ledStatus
            }}</el-descriptions-item>
            <el-descriptions-item label="SIM卡运营商">{{
              esservedatiledata.simCardOperator
            }}</el-descriptions-item>
            <el-descriptions-item label="使用场景">{{
              esservedatiledata.usageScenario
            }}</el-descriptions-item>
            <el-descriptions-item label="安装的车型号">{{
              esservedatiledata.installedVehicleModel
            }}</el-descriptions-item>
            <el-descriptions-item label="Email">{{
              esservedatiledata.email
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions direction="vertical" :column="3">
            <el-descriptions-item label="问题描述" :span="3">
              <p style="white-space: pre-line">
                {{ esservedatiledata.description }}
              </p>
            </el-descriptions-item>

            <el-descriptions-item label="图片" :span="16">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                v-for="{ url } in esservedatiledata.image"
                :key="url"
                :src="url"
                :previewSrcList="ESpreviewList"
              />
            </el-descriptions-item>
            <el-descriptions-item label="附件" :span="16">
              <p
                v-for="{ url, path } in esservedatiledata.attachment"
                :key="url"
              >
                <a :href="url" target="_blank">{{ path }}</a>
              </p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>

      <template v-if="kfdatile.orderNo">
        <div class="destitle">客服详情</div>
        <div class="desbody">
          <el-descriptions :column="3">
            <el-descriptions-item label="客服">{{
              kfdatile.createdName
            }}</el-descriptions-item>
            <el-descriptions-item label="客户">{{
              kfdatile.customerName
            }}</el-descriptions-item>
            <el-descriptions-item label="客户手机号">{{
              kfdatile.phoneNumber
            }}</el-descriptions-item>
            <el-descriptions-item label="客诉标题">{{
              kfdatile.title
            }}</el-descriptions-item>
            <el-descriptions-item label="问题原因">{{
              kfdatile.reason
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              kfdatile.createdTime
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions direction="vertical" :column="3">
            <el-descriptions-item label="问题描述" :span="3">
              <p style="white-space: pre-line">
                {{ kfdatile.description }}
              </p>
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="3">{{
              kfdatile.remark
            }}</el-descriptions-item>
            <el-descriptions-item label="图片" :span="3">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                fit="fit"
                v-for="{ url } in kfdatile.image"
                :key="url"
                :src="url"
                :previewSrcList="KFpreviewList"
              />
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>

      <div class="destitle">FAE详情</div>
      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item label="关键字">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item label="当前卡点">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题处理方案" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item label="描述" :span="3">
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="客户原话/其他" :span="3">{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item label="附件" :span="3">
            <p v-for="{ url, path } in FAEdatile.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <template v-if="this.yanfadatile.orderNo">
        <div class="destitle">研发详情</div>
        <div class="desbody">
          <el-descriptions :column="3">
            <el-descriptions-item :label="$t('createdTime')">{{
              yanfadatile.createdTime
            }}</el-descriptions-item>
            <el-descriptions-item :label="$t('zhuangtai')">{{
              attributeoptions[yanfadatile.attribute] &&
              attributeoptions[yanfadatile.attribute].label
            }}</el-descriptions-item>
            <el-descriptions-item :label="$t('personLiable')">{{
              yanfadatile.personLiable
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions direction="vertical" :column="3">
            <el-descriptions-item label="解释说明" :span="3">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in remakelinedate"
                  :key="index"
                  :timestamp="activity.time"
                >
                  {{ activity.detail }}
                </el-timeline-item>
              </el-timeline>
            </el-descriptions-item>

            <el-descriptions-item :label="$t('tapdlianjie')" :span="3">{{
              yanfadatile.tapdLink
            }}</el-descriptions-item>

            <el-descriptions-item :label="$t('yfkd')" :span="3">{{
              yanfadatile.developStuckPoint
            }}</el-descriptions-item>

            <el-descriptions-item :label="$t('jjfangan')" :span="3">{{
              yanfadatile.solution
            }}</el-descriptions-item>

            <el-descriptions-item label="图片" :span="3">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                fit="fit"
                v-for="{ url } in yanfadatile.imageInfos"
                :key="url"
                :src="url"
                :previewSrcList="YFpreviewList"
              />
            </el-descriptions-item>

            <el-descriptions-item label="附件" :span="3">
              <p v-for="{ url, path } in yanfadatile.fileInfos" :key="url">
                <a :href="url" target="_blank">{{ path }}</a>
              </p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { SearchOrderUrl, ExportExlUrl } from "@/api/order";
import { getdeptlisturl } from "@/api/jurisdiction";
import {
  SearchFaeOrderUrl,
  FaeExportExlUrl,
  getOneFaeUrl,
  gettimeline,
} from "@/api/fae";
import {
  softwareformlist,
  getOnesoftware,
  DevelopExportExlUrl,
} from "@/api/software";
import KFDetaileView from "./components/detailewindows";
import { translateDataToTree } from "@/utils/setMethods";
import { suanfa } from "@/utils/index";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
} from "@/options/index";

export default {
  name: "ArticleIndex",
  components: { KFDetaileView },
  props: {},
  data() {
    return {
      ifgaoji: false,
      remakelinedate: [],
      solutionlinedata: [],
      FAEdatile: {},
      kfdatile: {},
      yanfadatile: {},
      treedata: [],
      selectdept: "",
      form: {
        deptId: 0,
        ignoreUserFlag: true,
        workOrderNo: null,
        stations: "1,2,3",
        createdByStations: "0,1",
        creatTimeBefore: null,
        createTimeAfter: null,
        customerName: null,
        phoneNumber: null,
        processes: null,
        type: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
      },

      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,

      transformFlagoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      showremake: false,

      detaileVisible: false,
      dataildata: {},
      options: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "App故障",
        },
        {
          value: "1",
          label: "系统故障",
        },
        {
          value: "2",
          label: "硬件维修",
        },
        {
          value: "3",
          label: "技术支持",
        },
        {
          value: "4",
          label: "使用咨询",
        },
        {
          value: "5",
          label: "商务咨询",
        },
        {
          value: "6",
          label: "投诉建议",
        },
        {
          value: "7",
          label: "经销商问题",
        },
      ],
      stateoption: [
        {
          value: "0",
          label: "未完成",
        },
        {
          value: "1",
          label: "已完成",
        },
      ],
      value: "",
      articles: [], // 文章数据列表
      processStatus: [
        { status: 0, text: "未完成", type: "primary" }, // 1
        { status: 1, text: "完成", type: "info" }, // 1
        { status: 2, text: "待评价", type: "info" }, // 1
        { status: 3, text: "已转出", type: "primary" }, // 1
        { status: 4, text: "已完成", type: "warning" }, // 1
        { status: 5, text: "待完成", type: "danger" }, // 1
      ],
      articleStatus: [
        { status: 0, text: "APP故障", type: "info" }, // 1
        { status: 1, text: "系统故障", type: "info" }, // 1
        { status: 2, text: "硬件维修", type: "info" }, // 1
        { status: 3, text: "技术支持", type: "info" }, // 1
        { status: 4, text: "使用咨询", type: "info" }, // 4
        { status: 5, text: "商务咨询", type: "info" }, // 1
        { status: 6, text: "投诉建议", type: "info" }, // 4
        { status: 7, text: "经销商问题", type: "info" }, // 4
      ],
      Station: [
        { status: 0, text: "客户", type: "success" }, // 1
        { status: 1, text: "客服", type: "danger" }, // 1
        { status: 2, text: "FAE", type: "warning" }, // 1
        { status: 3, text: "研发", type: "success" }, // 1
      ],
      progress: [
        { status: 0, text: "未完成", type: "success" }, // 1
        { status: 1, text: "已完成", type: "danger" }, // 1
        { status: 2, text: "已评价", type: "warning" }, // 1
        { status: 3, text: "已转出", type: "warning" }, // 1
        { status: 4, text: "完成", type: "warning" }, // 1
        { status: 5, text: "待完成", type: "info" }, // 1
      ],

      urgentStatus: [
        { status: 0, text: "特殊加急", type: "danger" }, // 1
        { status: 1, text: "较为紧急", type: "warning" }, // 1
        { status: 2, text: "一般紧急", type: "primary" }, // 1
        { status: 3, text: "普通客诉", type: "info" }, // 1
      ],
      urgentStatustext: [
        "低", // 1
        "中", // 1
        "高", // 1
        "极高", // 1
      ],
      urgentoption: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "特殊加急",
        },
        {
          value: "1",
          label: "较为紧急",
        },
        {
          value: "2",
          label: "一般紧急",
        },
        {
          value: "3",
          label: "普通客诉",
        },
      ],
      totalCount: 0, // 总数据条数

      status: null, // 查询文章的状态，不传就是全部
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading

      timelinedata: null,
      timelineViewShow: false,

      ESpreviewList: [],

      planVisable: false,
      remarklist: [],
      yanfadataildata: {},
      FAEpreviewList: [],
      KFpreviewList: [],
      esservedatiledata: {},

      steptype: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.stations = "1,2,3";
    this.loadArticles(1);
    this.getdeptlist();
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    async handleSelectDept(val) {
      if (val) {
        this.selectdept = val[val.length - 1];
      }
      this.form.deptId = this.selectdept;
      this.loadArticles(1);
    },
    async getdeptlist() {
      try {
        const res = await getdeptlisturl();
        if (res.data.code === 200) {
          this.treedata = translateDataToTree(res.data.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    clearAndSearch() {
      this.form.workOrderNo = null;
      this.form.creatTimeBefore = null;
      this.form.createTimeAfter = null;
      this.form.phoneNumber = null;
      this.form.process = null;
      this.form.type = null;
      this.loadArticles(1);
    },
    transtoyanfa(row) {
      console.log(row);
      this.form.stations = "3";
      this.form.workOrderNo = row.orderNo;
      this.loadArticles(1);
    },
    transtofae(row) {
      console.log(row);
      this.form.stations = "2,3";
      this.form.workOrderNo = row.orderNo;
      this.loadArticles(1);
    },
    async addplan(row, column, event) {
      this.remakelinedate = [];
      this.planVisable = true;
      this.kfdatile = {};
      this.yanfadatile = {};
      this.FAEdatile = {};
      this.esservedatiledata = {};
      try {
        const res = await getOnesoftware({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          if (res.data.data.customerWorkOrderVo) {
            this.kfdatile = {
              ...res.data.data.customerWorkOrderVo,
              // attachment: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              image: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
                ({ path, url }) => ({
                  path,
                  url,
                })
              ),
            };
            this.KFpreviewList = (
              res.data.data.customerWorkOrderVo.fileInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
            };
          }

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };
          if (res.data.data.ecServeClientWorkOrderVo) {
            this.esservedatiledata = {
              ...res.data.data.ecServeClientWorkOrderVo,
              attachment: (
                res.data.data.ecServeClientWorkOrderVo.fileInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
              image: (
                res.data.data.ecServeClientWorkOrderVo.imageInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
            };

            // 电商详情字段转换
            this.esservedatiledata.type = {
              0: "Unable to activate",
              1: "Offline",
              2: "APP",
              3: "Locate",
              4: "SIM card",
              5: "Package",
              6: "other",
            }[this.esservedatiledata.type];

            this.esservedatiledata.usageScenario = {
              0: "Vehicle",
              1: "Human",
              2: "Asset",
              3: "Other",
            }[this.esservedatiledata.usageScenario];

            this.ESpreviewList = (
              res.data.data.ecServeClientWorkOrderVo?.imageInfos || []
            ).map(({ url }) => url);
          }
          // 预览图片列表
          this.FAEpreviewList = (
            res.data.data.technicalSupportWorkOrderVo.imageInfos || []
          ).map(({ url }) => url);
          this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          this.remakelinedate = suanfa(this.yanfadatile.remark);

          // process对应的进度条进度
          this.steptype =
            {
              0: 1,
              3: 3,
              5: 5,
              1: 7,
            }[res.data.data.technicalSupportWorkOrderVo.process || 0] || 5;
        } else {
          this.$message({});
        }
      } catch (e) {
      } finally {
      }
    },

    async showdtimeline(row) {
      try {
        const res = await gettimeline({
          workOrderId: row.workOrderId,
        });
        if (res.data.code === 200) {
          this.timelinedata = res.data.data;
          this.timelineViewShow = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    async openFAEDetails(row, column, event) {
      this.solutionlinedata = [];
      this.remakelinedate = [];
      this.kfdatile = {};
      this.yanfadatile = {};
      this.FAEdatile = {};
      this.esservedatiledata = {};
      try {
        const res = await getOneFaeUrl({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          if (res.data.data.customerWorkOrderVo) {
            this.kfdatile = {
              ...res.data.data.customerWorkOrderVo,
              image: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
                ({ path, url }) => ({
                  path,
                  url,
                })
              ),
            };
            this.KFpreviewList = (
              res.data.data.customerWorkOrderVo.fileInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
            };
          }

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };
          if (res.data.data.ecServeClientWorkOrderVo) {
            this.esservedatiledata = {
              ...res.data.data.ecServeClientWorkOrderVo,
              attachment: (
                res.data.data.ecServeClientWorkOrderVo.fileInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
              image: (
                res.data.data.ecServeClientWorkOrderVo.imageInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
            };

            // 电商详情字段转换
            this.esservedatiledata.type = {
              0: "Unable to activate",
              1: "Offline",
              2: "APP",
              3: "Locate",
              4: "SIM card",
              5: "Package",
              6: "other",
            }[this.esservedatiledata.type];

            this.esservedatiledata.usageScenario = {
              0: "Vehicle",
              1: "Human",
              2: "Asset",
              3: "Other",
            }[this.esservedatiledata.usageScenario];

            this.ESpreviewList = (
              res.data.data.ecServeClientWorkOrderVo?.imageInfos || []
            ).map(({ url }) => url);
          }
          // 预览图片列表
          this.FAEpreviewList = (
            res.data.data.technicalSupportWorkOrderVo.imageInfos || []
          ).map(({ url }) => url);
          this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          this.remakelinedate = suanfa(this.yanfadatile.remark);

          // process对应的进度条进度
          this.steptype =
            {
              0: 1,
              3: 3,
              5: 5,
              1: 7,
            }[res.data.data.technicalSupportWorkOrderVo.process || 0] || 5;
        } else {
          this.$message({});
        }
      } catch (e) {
      } finally {
        this.planVisable = true;
      }
    },
    openDetails(row, column, event) {
      this.detaileVisible = true;
      this.dataildata = row;
    },
    async exportexcel() {
      try {
        let res;
        if (this.form.stations == "1,2,3") {
          res = await ExportExlUrl(this.form);
        } else if (this.form.stations == "2,3") {
          res = await FaeExportExlUrl(this.form);
        } else if (this.form.stations == "3") {
          res = await DevelopExportExlUrl(this.form);
        }

        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("href", objectUrl);
          a.setAttribute(
            "download",
            decodeURIComponent(
              /filename=(.*)/.exec(res.headers["content-disposition"])[1]
            )
          );
          a.click();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    changedata(data) {
      this.dataildata = data;
      this.ChangeVisible = true;
      this.detaileVisible = false;
    },

    async loadArticles(page = 1) {
      // 展示加载中 loading
      (this.articles = []), (this.loading = true);
      try {
        let res;
        if (this.form.stations == "1,2,3") {
          res = await SearchOrderUrl(this.form);
        } else if (this.form.stations == "2,3") {
          res = await SearchFaeOrderUrl(this.form);
        } else if (this.form.stations == "3") {
          res = await softwareformlist(this.form);
        }

        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },

    onCurrentChange(page) {
      this.loadArticles(page);
    },

    onDeleteArticle(articleId) {
      this.$confirm("确认删除吗？", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 确认执行这里
          deleteArticle(articleId.toString()).then((res) => {
            // 删除成功，更新当前页的文章数据列表
            this.loadArticles(this.form.page);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // 找到数据接口
      // 封装请求方法
      // 删除请求调用
      // 处理响应结果
    },
  },
};
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
